import './SearchBar.scss';
import sendEvent from '../../../helpers/tracking';
import { useMobile } from '../../../helpers/hooks';
import { submitSearchForm } from '../../../helpers/form';

import Search from
  '../../../shared/assets/icons/redesign/input-search.svg';

export default function SearchBar() {
  const [isMobile] = useMobile();

  return (
    <form
      className="searchBar"
      onSubmit={e => submitSearchForm(e)}>
      <Search className="searchBar-icon" />

      <input
        name="search"
        onClick={() => sendEvent(...(isMobile
          ? ['dashboard-partner-mobile', 'dashboard-partner-mobile-searchbox']
          : ['dashboard-partner-desktop', 'dashboard-partner-desktop-searchbox']))}
        placeholder="Zoek naar artikelen, product of dienst"
        className="searchBar-input"
      />
    </form>
  );
}
