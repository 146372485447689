import Head from 'next/head';

import SearchBar from 'plaza-react-components/src/components/redesign/SearchBar/SearchBar.jsx';

export default function Custom404() {
  return (
    <>
      <Head>
        <title>404</title>
      </Head>

      <div className="custom404Page">
        <h1 className="custom404Page-title">404 error.</h1>
        <h2 className="custom404Page-subtitle">Deze pagina bestaat niet.</h2>
        <SearchBar />
      </div>
    </>
  );
}
